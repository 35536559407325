.MuiStepButton-root {
    font-size: inherit;
}
.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0 !important;
}
.sectionProgressIcon {
    font-size: 1.5em;
    line-height: 1em;
    height: 24px !important;
}
