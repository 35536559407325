html {
  height: 100%;
}
body {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  margin: 0;
  font-family: Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Cantarell,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #f4fafa;
}
#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
